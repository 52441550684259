import styled from "styled-components";

export const Link = styled.a.attrs(() => ({
  className: `
    text-base
    font-medium
    text-gray-500
    hover:text-gray-900
    `,
}))``;
