import { CheckboxProps } from "../../../@types/props/components/atoms/Checkbox";
import { Label, Input, Span } from "./css";

export const Checkbox = ({ title, onChange, name, checked }: CheckboxProps) => {
  return (
    <Label>
      {title}
      <Input onChange={onChange} checked={checked} name={name} />
      <Span />
    </Label>
  );
};
