import { FieldProps } from "../../../@types/props/components/atoms/Field";
import PlacesAutocomplete from "react-places-autocomplete";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import { Input, Textarea } from "./css";

export const Field = ({
  type = "text",
  placeholder,
  name,
  value,
  onChange,
  onSelect,
  extendCSS,
}: FieldProps) => {
  switch (type) {
    case "address":
      return (
        <PlacesAutocomplete
          value={value}
          onChange={onChange}
          onSelect={onSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "location-search-input",
                })}
                onChange={onChange}
                name={name}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      );
      break;

    case "date":
      return (
        <Calendar value={value} onChange={onChange} shouldHighlightWeekends />
      );
      break;

    case "textArea":
      return (
        <Textarea
          extendCSS={extendCSS}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
        />
      );
      break;

    default: //text
      return (
        <Input
          extendCSS={extendCSS}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
        />
      );
      break;
  }
};
