import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  border-radius: 0.395rem;
  padding: 1.096rem;
`;

export const FAQContainer = styled.div`
  display: inline-block;
  width: calc(100% - 1.5rem);
`;

export const Title = styled.h5`
  margin: 0;
  padding: 0;
  color: #252b42;
  font-weight: 700;
  font-size: 0.980rem;
  line-height: 1.063rem;
`;

export const Body = styled.h6`
  margin: 0;
  margin-top: 0.519rem;
  padding: 0;
  color: #737373;
  font-weight: 600;
  line-height: 1.063rem;
  font-size: 0.814rem;
`;
