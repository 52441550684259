import styled from "styled-components";

export const Container = styled.div`
  padding: 1.313rem 1.5rem;
  border-radius: 0.219rem;
  background-color: #fff;
  border: 0.044rem solid #dedede;
`;

export const Name = styled.div`
  font-size: 0.614rem;
  font-weight: 600;
  color: #000000;
  line-height: 1.063rem;
  margin-bottom: 1.125rem;
`;

export const Content = styled.div`
  color: #737373;
  font-size: 0.614rem;
  font-weight: 600;
  line-height: 1.063rem;
  white-space: pre-wrap;
`;

export const StarContainer = styled.div`
  margin-top: 3.563rem;
  display: flex;
  justify-content: center;
`;
