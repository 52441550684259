import styled from "styled-components";

export const Grid = styled.div.attrs(() => ({
  className: `
  max-w-7xl
  mx-auto`,
}))`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 1.313rem;
  grid-row-gap: 2.563rem;
`;
