import { FlatCheckboxProps } from "../../../@types/props/components/atoms/FlatCheckbox";
import { Div, Input, Label } from "./css";

export const FlatCheckbox = ({
  title,
  onChange,
  checked,
  name,
}: FlatCheckboxProps) => {
  return (
    <Label>
      <Input onChange={onChange} checked={checked} name={name} />
      <Div>{title}</Div>
    </Label>
  );
};
