import { StarsProps } from "../../../@types/props/components/atoms/Stars";
import filledStar from "../../../assets/images/filled-star.svg";
import { Container } from "./css";

export const Stars = ({ stars }: StarsProps) => {
  return (
    <div>
      {[...Array(stars).keys()].map((e) => (
        <Container key={e}>
          <img src={filledStar} />
        </Container>
      ))}
    </div>
  );
};
