import React from "react";
import { Header } from "./components/organisms/Header";
import logo from "./assets/images/logo.png";
import outlineSupport from "./assets/images/outlineSupport.svg";
import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router";
import { Home } from "./components/pages/Home";
import { Form } from "./components/pages/Form";
import { Footer } from "./components/molecules/Footer";

export const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Header
          logo={{
            imgSrc: logo,
            imgClassName: "h-8 w-auto sm:h-10",
            imgAlt: "",
            href: "#",
            sr: "Workflow",
          }}
          menus={[
            {
              withChildren: true,
              name: "Services",
              sub: [
                {
                  name: "Test",
                  href: "http://google.com",
                  icon: outlineSupport,
                  description: "test description",
                },
              ],
            },
            {
              withChildren: false,
              name: "Pricing",
              href: "http://google.com",
            },
          ]}
        ></Header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service/:service" element={<Form />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
};
