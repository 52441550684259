import styled from "styled-components";

export const Wrapper = styled.div.attrs(() => ({
  className: "max-w-7xl mx-auto",
}))``;

export const TestimonialContainer = styled.div`
  display: flex;
  justify-content: center;
`;
