import styled, { css } from "styled-components";
import { ButtonElementProps } from "../../../@types/props/components/atoms/Button";
import { StyledComponentProps } from "../../../@types/props/global";

export const ButtonElement = styled.button.attrs(() => ({
  type: "button",
  className: `
 text-white p-3 rounded-md font-bold`,
}))`
  width: ${(props: ButtonElementProps) =>
    props.variant === "half" ? "49%" : "100%"};
  display: block;
  color:black;
  font-size:13px;
  background-color: #fecc47;
  ${(props: StyledComponentProps & ButtonElementProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;
