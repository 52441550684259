import styled from "styled-components";

export const Div = styled.div.attrs(() => ({
  className: `
  py-1.5 px-1.5 block w-full focus:border-gray-300 focus:bg-white focus:ring-0 border rounded border-gray-300 hover:text-white hover:bg-blue-700`,
}))``;

export const Label = styled.label`
  & {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  & input:checked ~ ${Div} {
    background: #1d59d8;
    color: white;
  }
`;

export const Input = styled.input.attrs(() => ({
  type: "checkbox",
}))`
  /* Hide the browser's default checkbox */
  & {
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;
