import styled from "styled-components";

export const Label = styled.label`
  /* The container */
  & {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &:hover input ~ span {
    background-color: #ccc;
    
  }

  & input:checked ~ span {
    background-color: black;
  }
`;

export const Input = styled.input.attrs(() => ({
  type: "radio",
}))`
  /* Hide the browser's default checkbox */
  & {
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &:hover input ~ span {
    background-color: #ccc;
  }

  &:checked ~ span {
    background-color: #2196f3;
  }

  &:checked ~ span:after {
    display: block;
  }
`;

export const Span = styled.span`
  & {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    border-radius: 15px;
  border: solid 1px #cfcfcf;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 6px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
  }
`;
