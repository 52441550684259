import { Body, Container, FAQContainer, Title } from "./css";
import arrowRight from "../../../assets/images/arrow-right.svg";
import { FAQProps } from "../../../@types/props/components/molecules/FAQ";

export const FAQ = ({ title, body }: FAQProps) => {
  return (
    <Container>
      <img
        style={{
          display: "inline-block",
          verticalAlign: "top",
          marginRight: "0.875rem",
        }}
        src={arrowRight}
      />
      <FAQContainer>
        <Title>{title}</Title>
        <Body>{body}</Body>
      </FAQContainer>
    </Container>
  );
};
