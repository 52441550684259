import React from "react";
import { TitleProps } from "../../../@types/props/components/atoms/Title";
import {
  TitleForm,
  TitleFormPrice,
  TitleFormPriceNew,
  TitleFormPriceOld,
  TitleFormSub,
  TitleSimple,
  TitleHomeH2,
  TitleFeedbackForm,
} from "./css";

export const Title = ({
  type = "simple",
  children,
  params,
  extendCSS,
}: TitleProps) => {
  return (
    <React.Fragment>
      {type === "simple" && <TitleSimple>{children}</TitleSimple>}
      {type === "form" && (
        <TitleForm extendCSS={extendCSS}>{children}</TitleForm>
      )}
      {type === "formPrice" && (
        <TitleFormPrice>
          {params ? (
            <React.Fragment>
              <TitleFormPriceOld>${params.oldPrice}</TitleFormPriceOld>{" "}
              <TitleFormPriceNew>${params.newPrice}</TitleFormPriceNew>
            </React.Fragment>
          ) : (
            <React.Fragment>${children}</React.Fragment>
          )}
        </TitleFormPrice>
      )}
      {type === "formSub" && <TitleFormSub>{children}</TitleFormSub>}
      {type === "homeH2" && (
        <TitleHomeH2 extendCSS={extendCSS}>{children}</TitleHomeH2>
      )}
      {type === "feedbackForm" && (
        <TitleFeedbackForm extendCSS={extendCSS}>{children}</TitleFeedbackForm>
      )}
    </React.Fragment>
  );
};
