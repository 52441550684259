import { TestimonialProps } from "../../../@types/props/components/molecules/Testimonial";
import { Stars } from "../../atoms/Stars";
import { Container, Name, Content, StarContainer } from "./css";
export const Testimonial = ({ name, content, stars }: TestimonialProps) => {
  return (
    <div style={{ maxWidth: "16.438rem" }}>
      <Container>
        <Name>{name}</Name>
        <Content>{content}</Content>
      </Container>
      <StarContainer>
        <Stars stars={stars} />
      </StarContainer>
    </div>
  );
};
