import { FAQGridProps } from "../../../@types/props/components/organisms/FAQGrid";
import { Grid } from "./css";
import { FAQ as FAQItem } from "../../molecules/FAQ";

export const FAQGrid = ({ FAQ }: FAQGridProps) => {
  return (
    <Grid>
      {FAQ.map((item) => (
        <FAQItem title={item.title} body={item.body} />
      ))}
    </Grid>
  );
};
