import styled from "styled-components";

export const Label = styled.label`
  /* The container */
  & {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ span {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ span {
    background-color: #1d59d8;
  }
`;

export const Input = styled.input.attrs(() => ({
  type: "checkbox",
}))`
  /* Hide the browser's default checkbox */
  & {
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  /* Show the checkmark when checked */
  &:checked ~ span:after {
    display: block;
  }
`;

export const Span = styled.span`
  /* Create a custom checkbox */
  & {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #eee;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Style the checkmark/indicator */
  &:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
