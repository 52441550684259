import styled, { css } from "styled-components";
import { StyledComponentProps } from "../../../@types/props/global";

export const Container = styled.div`
  padding: 5rem 5rem;
  max-width: 45rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 7.5rem;

  label {
    display: inline-block;
    margin-right: 0.5rem;
  }
`;

export const Cols2 = styled.div.attrs(() => ({
  className: `
  lg:grid-cols-2
  lg:gap-x-3
  `,
}))`
  display: grid;
  ${(props: StyledComponentProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;
