import { RadioProps } from "../../../@types/props/components/atoms/Radio";
import { Label, Input, Span } from "./css";

export const Radio = ({
  title,
  onChange,
  name,
  checked,
  value,
}: RadioProps) => {
  return (
    <Label>
      {title}
      <Input
        onChange={onChange}
        checked={checked === value ? true : false}
        name={name}
        value={value}
      />
      <Span />
    </Label>
  );
};
