import React from "react";
import { Button } from "./css";
import { ScreenReaderSpan } from "../../atoms/ScreenReaderSpan";
import { Image } from "../../atoms/Image";
import outlineMenu from "../../../assets/images/outlineMenu.svg";
import { MobileMenuButtonProps } from "../../../@types/props/components/molecules/MobileMenuButton";

export const MobileMenuButton = (props: MobileMenuButtonProps) => {
  return (
    <Button onClick={props.onClick}>
      <ScreenReaderSpan>{props.sr}</ScreenReaderSpan>
      <Image className="h-6 w-6" src={outlineMenu} />
    </Button>
  );
};
