import React, { useRef } from "react";
import { Container, Button, SubContainer, Sub, SubItem } from "./css";
import { Image } from "../Image";
import solidChevronDown from "../../../assets/images/solidChevronDown.svg";
import { MenuItemWithChildrenProps } from "../../../@types/props/components/atoms/MenuItemWithChildren";

export const MenuItemWithChildren = (props: MenuItemWithChildrenProps) => {
  const onClick = () => {
    $(() => {
      if (SubContainerRef?.current) $(SubContainerRef.current).fadeToggle();
    });
  };
  const SubContainerRef = useRef<HTMLDivElement>(null);
  return (
    <Container>
      <Button onClick={onClick}>
        <span>{props.name}</span>
        <Image
          className="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
          src={solidChevronDown}
        />
      </Button>
      <SubContainer ref={SubContainerRef}>
        <Sub>
          {props?.sub.map((item) => (
            <SubItem href={item.href}>
              <Image
                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                src={item.icon}
              />
              <div className="ml-4">
                <p className="text-base font-medium text-gray-900">
                  {item.name}
                </p>
                {item.description && (
                  <p className="mt-1 text-sm text-gray-500">
                    {item.description}
                  </p>
                )}
              </div>
            </SubItem>
          ))}
        </Sub>
      </SubContainer>
    </Container>
  );
};
