import styled from "styled-components";

export const Button = styled.button.attrs(() => ({
  ["aria-expanded"]: false,
  type: "button",
  className: `
  bg-white
  rounded-md
  p-2
  inline-flex
  items-center
  justify-center
  text-gray-400
  hover:text-gray-500
  hover:bg-gray-100
  focus:outline-none
  focus:ring-2
  focus:ring-inset
  focus:ring-indigo-500
    `,
}))``;
