import styled, { css } from "styled-components";
import { StyledComponentProps } from "../../../@types/props/global";

export const TitleSimple = styled.h1``;

export const TitleForm = styled.h1.attrs(() => ({
  className: `
  text-2xl
  font-bold
  `,
}))`
  & {
    ${(props: StyledComponentProps) =>
      props?.extendCSS &&
      css`
        ${props.extendCSS}
      `};
  }
`;

export const TitleFormPrice = styled.h3.attrs(() => ({
  className: `
  text-xl
  xl:text-sm`,
}))``;

export const TitleFormPriceOld = styled.span.attrs(() => ({
  className: `
  `,
}))`
  text-decoration: line-through;
`;

export const TitleFormPriceNew = styled.span.attrs(() => ({
  className: `
  font-bold
  text-red-500
  `,
}))``;

export const TitleFormSub = styled.h2.attrs(() => ({
  className: `
  text-2xl
  font-bold`,
}))``;

export const TitleHomeH2 = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1.75rem;
  font-weight: 700;
  color: #252b42;
  line-height: 2.5rem;
  ${(props: StyledComponentProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;

export const TitleFeedbackForm = styled.div`
  color: "#3C4B56";
  font-weight: 700;
  font-size: 0.9rem;
  padding-bottom: 1.5rem;
  ${(props: StyledComponentProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;
