import { ButtonProps } from "../../../@types/props/components/atoms/Button";
import { ButtonElement } from "./css";

export const Button = ({
  children,
  onClick,
  variant,
  extendCSS,
  disabled = false,
}: ButtonProps) => {
  return (
    <ButtonElement
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      extendCSS={extendCSS}
    >
      {children}
    </ButtonElement>
  );
};
