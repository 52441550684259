import { createSlice, createSelector } from "@reduxjs/toolkit";

import { takeLatest, put, putResolve } from "redux-saga/effects";

import { success } from "@redux-requests/core";

const FETCH_GLOBAL = "FETCH_GLOBAL";

//Slice
const globalSlice = createSlice({
  name: "global",
  initialState: {},
  reducers: {
    GET_GLOBAL() {},
  },
  extraReducers: {
    [success(FETCH_GLOBAL)]: (state, action) => {
      state = action.response.data;
    },
  },
});

/*
In slice you can listen to AJAX request events
import { success, error } from "@redux-requests/core";
  extraReducers: {
    [success(FETCH_GLOBAL)]: (state, action) => {
action.response.data
    },
    [error(FETCH_GLOBAL)]: (state, actio) => {
    },
  },
*/

//Selectors
const selectRoot = (state: any) => state;

const selectGlobalRoot = createSelector(selectRoot, (state) => state);

//Requests
const fetchGlobal = () => ({
  type: FETCH_GLOBAL,
  request: {
    url: "http://localhost:5000/Global.json",
    // you can put here other Axios config attributes, like method, data, headers etc.
  },
});

//Sagas
function* getGlobal() {
  // put isLoading to global state
  const { data, error } = yield putResolve(fetchGlobal());
}

//Watcher sagas
function* getGlobalWatcher() {
  yield takeLatest(globalSlice.actions.GET_GLOBAL.type, getGlobal);
}

export const { actions, reducer } = globalSlice;
export { selectGlobalRoot };
export const watcherSagas = [getGlobalWatcher()];
