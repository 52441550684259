import styled, { css } from "styled-components";
import { StyledComponentProps } from "../../../@types/props/global";

export const Paragraph = styled.p`
  padding: 0;
  margin: 0;
  ${(props: StyledComponentProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;

export const HomeParagraph = styled.p`
  padding: 0;
  margin: 0;
  color: #929292;
  font-weight: 500;
  font-size: 0.914rem;
  ${(props: StyledComponentProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;

export const Span = styled.span`
  ${(props: StyledComponentProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;

export const FeedbackFormParagraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.0rem;
  padding-top:1rem;
  padding-bottom:1rem;
  color: "#707B84";
  ${(props: StyledComponentProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;
