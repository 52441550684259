import { RefObject } from "react";
import styled from "styled-components";

export const Container = styled.div.attrs(() => ({
  className: "relative",
}))``;

export const Button = styled.button.attrs(() => ({
  ["aria-expanded"]: false,
  type: "button",
  className: `
  text-gray-500
    group
    bg-white
    rounded-md
    inline-flex
    items-center
    text-base
    font-medium
    hover:text-gray-900
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-indigo-500`,
}))``;

export const SubContainer = styled.div.attrs(
  (props: { ref: RefObject<HTMLDivElement> }) => ({
    ...props,
    className: `
  hidden
  absolute
  z-10
  left-1/2
  transform
  -translate-x-1/2
  mt-3
  px-2
  w-screen
  max-w-md
  sm:px-0
  rounded-lg
  shadow-lg
  ring-1
  ring-black
  ring-opacity-5
  overflow-hidden
  `,
  })
)``;

export const Sub = styled.div.attrs(() => ({
  className: `
  relative
  grid
  gap-6
  bg-white
  px-5
  py-6
  sm:gap-8
  sm:p-8
  `,
}))``;

export const SubItem = styled.a.attrs(() => ({
  className: `
  -m-3
  p-3
  flex
  items-start
  rounded-lg
  hover:bg-gray-50
  `,
}))``;
