import React from "react";
import { LogoProps } from "../../../@types/props/components/molecules/Logo";
import { ScreenReaderSpan } from "../../atoms/ScreenReaderSpan";
import { Image } from "../../atoms/Image";
import { Link } from "react-router-dom";

export const Logo = (props: LogoProps) => {
  return (
    <Link to={"/"}>
      <ScreenReaderSpan>{props.sr}</ScreenReaderSpan>
      <Image
        className={props.imgClassName}
        src={props.imgSrc}
        alt={props.imgAlt}
      />
    </Link>
  );
};
