import React, { useState } from "react";
import { HeaderProps } from "../../../@types/props/components/organisms/Header";
import { Container, LogoContainer, MobileMenuContainer } from "./css";
import { Logo } from "../../molecules/Logo";
import { MobileMenuButton } from "../../molecules/MobileMenuButton";
import { Menu } from "../../molecules/Menu";
import { MobileMenu } from "../../molecules/MobileMenu";

export const Header = (props: HeaderProps) => {
  const [mobileMenuHidden, setMobileMenuHidden] = useState(true);
  const toggleMenu = () => {
    setMobileMenuHidden(!mobileMenuHidden);
  };
  return (<div className="border-b-2" style={{backgroundColor:'white'}}>
    <Container>
      <LogoContainer>
        <Logo {...props.logo} />
      </LogoContainer>
      <MobileMenuContainer>
        <MobileMenuButton onClick={toggleMenu} sr="Open menu" />
      </MobileMenuContainer>
      <Menu menus={props.menus} />
      <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
        <a
          href="#"
          className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-500 hover:bg-red-600"
        >
          Book a Pro
        </a>
      </div>
      <MobileMenu hidden={mobileMenuHidden} onClickClose={toggleMenu} />
    </Container>
    </div>
  );
};
