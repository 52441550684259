import styled, { css } from "styled-components";
import { StyledComponentProps } from "../../../@types/props/global";

export const Input = styled.input.attrs(() => ({
  type: "text",
}))`
  border: 0.063rem #dae0e8 solid;
  border-radius: 0.15rem;
  font-size: 0.9rem;
  padding-top:0.3rem;
  padding-bottom:0.3rem;
  padding-left:1rem;
  ${(props: StyledComponentProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;

export const Textarea = styled.textarea`
  border: 0.063rem #dae0e8 solid;
  border-radius: 0.15rem;
  font-size: 0.9rem;
  padding-top:0.5rem;
  padding-left:1rem;
  ${(props: StyledComponentProps) =>
    props?.extendCSS &&
    css`
      ${props.extendCSS}
    `};
`;
