import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { handleRequests } from "@redux-requests/core";
import axios from "axios";
import { createDriver } from "@redux-requests/axios";
import {
  reducer as globalReducer,
  watcherSagas as globalWatcherSagas,
} from "./global";

const sagaMiddleware = createSagaMiddleware();
const { requestsMiddleware } = handleRequests({
  driver: createDriver(axios),
});

const reducer = combineReducers({
  global: globalReducer,
});

function* rootSaga() {
  yield all([...globalWatcherSagas]);
}

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware({ thunk: false }),
      sagaMiddleware,
      ...requestsMiddleware,
    ];
  },
});

sagaMiddleware.run(rootSaga);

export default store;
