import { FlatRadioProps } from "../../../@types/props/components/atoms/FlatRadio";
import { Div, Input, Label } from "./css";

export const FlatRadio = ({
  title,
  onChange,
  name,
  checked,
  value,
}: FlatRadioProps) => {
  return (
    <Label>
      <Input name={name} onChange={onChange} checked={checked} value={value} />
      <Div>{title}</Div>
    </Label>
  );
};
