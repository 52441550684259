import React, { useState, useRef, useEffect } from "react";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import before from "../../../assets/images/before.jpg";
import after from "../../../assets/images/after.jpg";
const FIRST_IMAGE = {
  imageUrl: after,
};
const SECOND_IMAGE = {
  imageUrl: before,
};
export const Hero = () => {
  const DEMONSTRATION_DELAY = 6300;

  const DEFAULT_DURATION = 800;
  type Animation = {
    start: number;
    end: number;
    duration: number;
  };
  const START_POSITION = 15;
  const END_POSITION_1 = 55;
  const END_POSITION_2 = 90;
  const ANIMATIONS: Animation[] = [
    {
      start: START_POSITION,
      end: END_POSITION_1,
      duration: DEFAULT_DURATION,
    },
    // pause
    {
      start: END_POSITION_1,
      end: END_POSITION_1,
      duration: 30,
    },
    {
      start: END_POSITION_1,
      end: END_POSITION_2,
      duration: DEFAULT_DURATION,
    },
    // pause
    {
      start: END_POSITION_2,
      end: END_POSITION_2,
      duration: 1500,
    },
    {
      start: END_POSITION_2,
      end: START_POSITION,
      duration: DEFAULT_DURATION,
    },
  ];

  const [delimiterPercentPosition, setDelimiterPercentPosition] =
    useState<number>(START_POSITION);

  function timePhaseToCoordinadeDifferenceCoefficient(x: number) {
    return (Math.sin(x * Math.PI - Math.PI / 2) + 1) / 2;
  }
  const allAnimationsRef = useRef<Animation[]>([]);
  const animationStartTimeRef = useRef<number | null>(null);
  const animationPositionsRef = useRef<Animation | null>(null);
  const animate = (timestamp: number) => {
    let animationPositions = animationPositionsRef.current;

    if (!animationPositions) {
      const currentAnimation = allAnimationsRef.current.shift();
      if (!currentAnimation) {
        return;
      }
      animationPositions = animationPositionsRef.current = currentAnimation;
    }

    let animationStartTime = animationStartTimeRef.current;
    if (!animationStartTime) {
      animationStartTime = animationStartTimeRef.current = timestamp;
    }

    const {
      start: animationStartPosition,
      end: animationEndPosition,
      duration: animationDuration,
    } = animationPositions;

    if (timestamp > animationStartTime + animationDuration) {
      // End of animation
      setDelimiterPercentPosition(animationEndPosition);
      animationPositionsRef.current = null;
      animationStartTimeRef.current = null;
    } else {
      const animationPhase =
        (timestamp - animationStartTime) / animationDuration;
      const coordinatesDifference =
        (animationEndPosition - animationStartPosition) *
        timePhaseToCoordinadeDifferenceCoefficient(animationPhase);

      setDelimiterPercentPosition(
        animationStartPosition + coordinatesDifference
      );
    }

    window.requestAnimationFrame(animate);
  };

  const interval = useRef<NodeJS.Timeout | null>(null);

  const demonstrate = () => {
    const beforeAfterSliderRef: HTMLDivElement | null =
      document.querySelector(".beforeAfterSlider");
    if (beforeAfterSliderRef)
      beforeAfterSliderRef.onmousedown = () => {
        allAnimationsRef.current = [];
        animationStartTimeRef.current = null;
        animationPositionsRef.current = null;
        if (interval?.current) clearInterval(interval.current);
      };
    allAnimationsRef.current = [...ANIMATIONS];
    animationStartTimeRef.current = null;
    animationPositionsRef.current = null;
    window.requestAnimationFrame(animate);
    interval.current = setInterval(() => {
      allAnimationsRef.current = [...ANIMATIONS];
      animationStartTimeRef.current = null;
      animationPositionsRef.current = null;
      window.requestAnimationFrame(animate);
    }, DEMONSTRATION_DELAY);
  };

  useEffect(() => {
    return () => {
      if (interval?.current) clearInterval(interval.current);
    };
  }, []);

  return (
    <div className="max-w-7xl mx-auto grid grid-cols-1 gap-2 lg:gap-0 lg:grid-cols-2">
      <div className="px-4 mt-28 max-w-7xl sm:px-6 lg:px-8">
        <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 text-center xl:text-left">
          Service as soon as today
        </h1>
        <p className="mt-4 text-xl text-gray-500 text-center xl:text-left">
          In-home service from 9am-9pm. <br />
          Book online in minutes for service as soon as today!
        </p>
        <div className="mt-10 text-left">
          <a
            href="#"
            className="inline-block text-center bg-sky-500 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-blue-600">
            Book a service
          </a>
        </div>
      </div>
      <div className="px-4 mt-20 max-w-7xl sm:px-6 lg:px-8">
        <ReactBeforeSliderComponent
          className="beforeAfterSlider"
          firstImage={FIRST_IMAGE}
          secondImage={SECOND_IMAGE}
          withResizeFeel={true}
          onVisible={demonstrate}
          onChangePercentPosition={setDelimiterPercentPosition}
          currentPercentPosition={delimiterPercentPosition}
        />
      </div>
    </div>
  );
};
