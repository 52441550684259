import { useEffect, useRef, useState } from "react";
import ReactSlidy from "react-slidy";
import "react-slidy/lib/styles.css";
import { TestimonialsProps } from "../../../@types/props/components/organisms/Testimonials";
import { Testimonial } from "../../molecules/Testimonial";
import { TestimonialContainer, Wrapper } from "./css";

export const Testimonials = ({ testimonials }: TestimonialsProps) => {
  const [slide, setSlide] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout>();
  const onInit = () => {
    intervalRef.current = setInterval(() => {
      setSlide((slide) => {
        if (slide + 4 >= testimonials.length + 3) {
          return 0;
        } else {
          return slide + 4;
        }
      });
    }, 6500);
  };
  const clear = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
  };
  useEffect(() => {
    return clear;
  }, []);
  return (
    <Wrapper>
      <ReactSlidy
        slide={slide}
        doAfterInit={onInit}
        numOfSlides={4}
        infiniteLoop={true}
      >
        {testimonials.map((testimonial) => (
          <TestimonialContainer onMouseEnter={clear}>
            <Testimonial
              name={testimonial.name}
              content={testimonial.content}
              stars={testimonial.stars}
            />
          </TestimonialContainer>
        ))}
      </ReactSlidy>
    </Wrapper>
  );
};
