import styled from "styled-components";

export const Container = styled.div.attrs(() => ({
  className: `
bg-white-100
max-w-7xl
mx-auto
px-4
sm:px-6
lg:px-8
`,
}))``;

export const ServicesContainer = styled.div.attrs(() => ({
  className: `
max-w-2xl
mx-auto
py-16
sm:py-24
lg:py-32
lg:max-w-none
mt-6
space-y-12
lg:space-y-0
lg:grid
lg:grid-cols-6
lg:gap-x-6
`,
}))``;

export const Service = styled.div.attrs(() => ({
  className: `
group
relative
`,
}))``;

export const ServiceImageContaier = styled.div.attrs(() => ({
  className: `
relative
w-full
bg-white
rounded-sm
overflow-hidden
group-hover:opacity-75
`,
}))``;

export const ServiceTitle = styled.h3.attrs(() => ({
  className: `
mt-6
text-sm
text-center
xl:text-left
text-2xl
font-bold
from-black
xl:text-base
`,
}))``;

export const ServicePricing = styled.p.attrs(() => ({
  className: `
text-center
xl:text-left
text-xl
xl:text-sm
`,
}))``;
