import React from "react";
import { MenuProps } from "../../../@types/props/components/molecules/Menu";
import { MenuItemWithChildren } from "../../atoms/MenuItemWithChildren";
import { MenuItemWithoutChildren } from "../../atoms/MenuItemWithoutChildren";
import { MenuContainer } from "./css";

export const Menu = (props: MenuProps) => {
  return (
    <MenuContainer>
      {props.menus &&
        props.menus.map((menu) => {
          if (menu.withChildren) {
            return (
              <MenuItemWithChildren
                name={menu.name}
                sub={menu?.sub ? menu.sub : []}
              />
            );
          } else {
            return (
              <MenuItemWithoutChildren href={menu?.href ? menu.href : "#"}>
                {menu.name}
              </MenuItemWithoutChildren>
            );
          }
        })}
    </MenuContainer>
  );
};
