import styled from "styled-components";

export const Container = styled.header.attrs(() => ({
  className: `
    max-w-7xl
    mx-auto
    px-4
    sm:px-6
    flex
    relative
    bg-white
    justify-between
    items-center
    border-gray-100
    py-6
    md:justify-start
    md:space-x-10
    `,
}))``;

export const LogoContainer = styled.div.attrs(() => ({
  className: `
  flex
  justify-start
  lg:w-0
  lg:flex-1
  `,
}))``;

export const MobileMenuContainer = styled.div.attrs(() => ({
  className: `
  -mr-2
  -my-2
  md:hidden
  `,
}))``;
