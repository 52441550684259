import React from "react";
import { TextProps } from "../../../@types/props/components/atoms/Text";
import { Paragraph, Span, HomeParagraph, FeedbackFormParagraph } from "./css";

export const Text = ({ variant, children, extendCSS }: TextProps) => {
  return (
    <React.Fragment>
      {variant === "block" && (
        <Paragraph extendCSS={extendCSS}>{children}</Paragraph>
      )}
      {variant === "inline" && <Span extendCSS={extendCSS}>{children}</Span>}
      {variant === "homeP" && (
        <HomeParagraph extendCSS={extendCSS}>{children}</HomeParagraph>
      )}
      {variant === "feedbackForm" && (
        <FeedbackFormParagraph extendCSS={extendCSS}>
          {children}
        </FeedbackFormParagraph>
      )}
    </React.Fragment>
  );
};
