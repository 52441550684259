import React from "react";
import {
  Container,
  ServicesContainer,
  Service,
  ServiceImageContaier,
  ServiceTitle,
  ServicePricing,
} from "./css";
import { Image } from "../../atoms/Image";
import { ServicesProps } from "../../../@types/props/components/organisms/Services";
import { Link } from "react-router-dom";

export const Services = (props: ServicesProps) => {
  return (
    <Container>
      <ServicesContainer>
        {props.services.map((service) => (
          <Link to={`/service/${service.title}`}>
            <Service>
              <ServiceImageContaier>
                <Image
                  className={"object-center h-full w-full"}
                  src={service.imageSrc}
                  alt={service.imageAlt}
                />
              </ServiceImageContaier>
              <ServiceTitle>
                <a href={service.href}>
                  <span className="absolute inset-0"></span>
                  {service.title}
                </a>
              </ServiceTitle>
              <ServicePricing>
                <span className="startfrom">Starting from</span>
                {service.oldPrice && (
                  <span>
                    {" "}
                    <del>
                      <span className="amount">${service.oldPrice}</span>
                    </del>
                  </span>
                )}
                <span
                  className={
                    "price font-bold" + (service.oldPrice && " text-red-500")
                  }
                >
                  {" "}
                  ${service.price}
                </span>
              </ServicePricing>
            </Service>
          </Link>
        ))}
      </ServicesContainer>
    </Container>
  );
};
