import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { postForm } from "../../../queries/form";
import { Button } from "../../atoms/Button";
import { Checkbox } from "../../atoms/Checkbox";
import { Field } from "../../atoms/Field";
import { FlatCheckbox } from "../../atoms/FlatCheckbox";
import { FlatRadio } from "../../atoms/FlatRadio";
import { Title } from "../../atoms/Title";

type AppointmentDetail = {
  title: string;
  name?: string;
  price?: number;
  value?: string;
  withPrice?: boolean;
};

type AppointmentDetails = AppointmentDetail[];

type screenWithCheckboxes = {
  title: string;
  options: { title: string; price?: number; name: string; value?: string }[];
  resetButton?: string;
  addons?: { title: string; price: number; name: string }[];
  multiple: boolean;
};

type screenWithFields = {
  title: string;
  fields: {
    placeholder: string;
    type: "text" | "address" | "date";
    name: string;
  }[];
};

type Flows = {
  TV60OrSmaller: string[];
  TV65OrLarger: string[];
  CordAndPower: string[];
  TVAboveFireplace: string[];
  Soundbar: string[];
  HomeAudio: string[];
};

type Screens = {
  TVCables: screenWithCheckboxes;
  TVMount: screenWithCheckboxes;
  WallMaterial: screenWithCheckboxes;
  CordConcealmentAndPowerRelocationCables: screenWithCheckboxes;
  SoundbarCables: screenWithCheckboxes;
  SoundbarMount: screenWithCheckboxes;
  HomeAudioSpeakers: screenWithCheckboxes;
  HomeAudioCables: screenWithCheckboxes;
  Date: screenWithFields;
  Time: screenWithCheckboxes;
  Final: screenWithFields;
};

export const FormHeader = ({
  title,
  params,
  price,
}: {
  title: string;
  params?: { oldPrice: number; newPrice: number };
  price?: number;
}) => {
  return (
    <div style={{ marginTop: "2.75rem" }}>
      <Title type="form">{title}</Title>
      {(price || params) && (
        <Title type="formPrice" params={params}>
          {price}
        </Title>
      )}
    </div>
  );
};

export const FormBody = ({
  title,
  options,
  resetButton,
  addons,
  multiple,
  fields,
  formData,
  setFormData,
  onChange,
  onChangeCalendar,
  getCurrentScreenWithStep,
  showScreenWithStep,
  appointmentDetails,
  setAppointmentDetails,
  formBodyButtonsContainer,
}: {
  title: string;
  options?: { title: string; price?: number; name: string; value?: string }[];
  resetButton?: string;
  addons?: { title: string; price: number; name: string }[];
  multiple?: boolean;
  fields?: {
    placeholder: string;
    type: "text" | "address" | "date";
    name: string;
  }[];
  formData: any;
  setFormData: Dispatch<SetStateAction<{ Date: never[] }>>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCalendar: (data: any) => void;
  getCurrentScreenWithStep: () => {
    flow: keyof Flows;
    screen: keyof Screens;
    step: number;
  };
  showScreenWithStep: (flow: keyof Flows, step: number) => void;
  appointmentDetails: AppointmentDetails;
  setAppointmentDetails: Dispatch<SetStateAction<AppointmentDetails>>;
  formBodyButtonsContainer: React.Ref<HTMLDivElement>;
}) => {
  // const queryClient = useQueryClient();
  const { mutate: mutateForm, isLoading } = useMutation(postForm, {
    onSuccess: (data) => {
      const message = "success";
      alert(message);
    },
    onError: () => {
      alert("there was an error");
    },
    // onSettled: () => {
    //   queryClient.invalidateQueries("create");
    // },
  });
  const [buttonsLocked, setButtonsLocked] = useState(false);
  const currentScreen = getCurrentScreenWithStep();

  const previousStep = () => {
    setButtonsLocked(true);
    document.getElementById("formContainer")!.style.transition =
      "transform .3s, opacity .3s";
    document.getElementById("formContainer")!.style.opacity = "0";
    document.getElementById("formContainer")!.style.transform =
      "translate(2rem)";
    setTimeout(() => {
      document.getElementById("formContainer")!.style.transition =
        "transform 0s, opacity 0s";
      document.getElementById("formContainer")!.style.transform =
        "translate(-2rem)";
    }, 310);
    setTimeout(() => {
      document.getElementById("formContainer")!.style.transition =
        "transform .3s, opacity .3s";
      document.getElementById("formContainer")!.style.opacity = "1";
      document.getElementById("formContainer")!.style.transform =
        "translate(0rem)";
      showScreenWithStep(currentScreen.flow, currentScreen.step - 1);
      setButtonsLocked(false);
    }, 320);
  };

  const nextStep = () => {
    setButtonsLocked(true);
    document.getElementById("formContainer")!.style.transition =
      "transform .3s, opacity .3s";
    document.getElementById("formContainer")!.style.opacity = "0";
    document.getElementById("formContainer")!.style.transform =
      "translate(-2rem)";
    setTimeout(() => {
      document.getElementById("formContainer")!.style.transition =
        "transform 0s, opacity 0s";
      document.getElementById("formContainer")!.style.transform =
        "translate(2rem)";
    }, 310);
    setTimeout(() => {
      document.getElementById("formContainer")!.style.transition =
        "transform .3s, opacity .3s";
      document.getElementById("formContainer")!.style.opacity = "1";
      document.getElementById("formContainer")!.style.transform =
        "translate(0rem)";
      showScreenWithStep(currentScreen.flow, currentScreen.step + 1);
      setButtonsLocked(false);
    }, 320);
  };

  const submit = () => {
    const postData = {
      auth: "notBot",
      appointmentDetails,
      date: formData["Date"].map(
        (date: any) => `${date.month}/${date.day}/${date.year}`
      ),
      name: formData["FullName"],
      email: formData["Email"],
      phone: formData["PhoneNumber"],
      address: formData["Address"],
      // time: [
      //   formData.time1,
      //   formData.time2,
      //   formData.time3,
      //   formData.time4,
      //   formData.time5,
      // ],
    };
    mutateForm(postData);
  };

  return (
    <div id="formContainer" className="pb-8">
      <div className="mt-8">
        <Title type="formSub">{title}</Title>
      </div>
      {options &&
        !multiple &&
        options.map((option) => (
          <div style={{ marginTop: "0.75rem" }}>
            <FlatRadio
              title={`${option.title}${
                option?.price ? " (+$" + option.price + ")" : ""
              }`}
              onChange={onChange}
              checked={
                (formData?.[option.name]?.checked &&
                  formData?.[option.name]?.value === option?.value) ||
                false
              }
              name={option.name}
              value={option?.value || "on"}
            />
          </div>
        ))}
      {options &&
        multiple &&
        options.map((option) => (
          <div style={{ marginTop: "0.75rem" }}>
            <FlatCheckbox
              title={`${option.title}${
                option?.price ? " (+$" + option.price + ")" : ""
              }`}
              onChange={onChange}
              checked={formData[option.name]}
              name={option.name}
            />
          </div>
        ))}
      {options && multiple && resetButton && (
        <div style={{ marginTop: "0.75rem" }}>
          <FlatCheckbox
            title={resetButton}
            onChange={(e) => {
              let tmp = formData;
              tmp = formData;
              if (e.target.checked) {
                const optionNames = options.map((option) => option.name);

                let tmp1 = [...appointmentDetails];
                for (const optionName of optionNames) {
                  tmp[optionName] = false;

                  const index = tmp1.findIndex(
                    (item: AppointmentDetail) => item.name === optionName
                  );
                  if (index > -1) {
                    tmp1.splice(index, 1);
                  }
                }
                setAppointmentDetails(tmp1);
                tmp["reset" + currentScreen.screen] = true;
              } else {
                tmp["reset" + currentScreen.screen] = false;
              }

              setFormData(tmp);
            }}
            checked={formData["reset" + currentScreen.screen]}
            name={"reset" + currentScreen.screen}
          />
        </div>
      )}
      {addons && (
        <div>
          <div className="text-gray-700 font-bold pt-8 pb-7">Add-ons</div>
          {addons.map((addon) => (
            <Checkbox
              title={`${addon.title}${
                addon?.price && " (+$" + addon.price + ")"
              }`}
              onChange={onChange}
              checked={formData[addon.name]}
              name={addon.name}
            />
          ))}
        </div>
      )}
      {fields &&
        fields.map((field) => (
          <Field
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            onChange={field.type === "date" ? onChangeCalendar : onChange}
            value={formData[field.name]}
          />
        ))}

      <div ref={formBodyButtonsContainer}>
        {currentScreen.step === 0 && (
          <div className="pt-2">
            <Button onClick={nextStep}>Next</Button>
          </div>
        )}
        {currentScreen.step !== 0 && currentScreen.screen === "Final" && (
          <div className="pt-2">
            <Button onClick={submit}>Confirm Booking</Button>
          </div>
        )}
        {currentScreen.step !== 0 && currentScreen.screen !== "Final" && (
          <div
            className="pt-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              disabled={buttonsLocked}
              variant="half"
              onClick={previousStep}
            >
              Back
            </Button>
            <Button disabled={buttonsLocked} variant="half" onClick={nextStep}>
              Next
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export const FormFooter = ({
  appointmentDetails,
  footerToggle,
  setFooterToggle,
  formFooterContainer,
}: {
  appointmentDetails: AppointmentDetails;
  footerToggle: boolean;
  setFooterToggle: Dispatch<SetStateAction<boolean>>;
  formFooterContainer: React.Ref<HTMLDivElement>;
}) => {
  return (
    <div
      ref={formFooterContainer}
      onClick={() => setFooterToggle(!footerToggle)}
      style={{
        margin: "1rem",
        padding: "1rem",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.11)",
        borderRadius: "0.5rem",
        background: "white",
        zIndex: 2,
        width: "calc(100% - 6rem)",
      }}
      className="lg:relative lg:inset-auto lg:!w-full lg:h-fit fixed bottom-0"
    >
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>Appointment details</th>
            <th style={{ textAlign: "right" }}>
              Total: $
              {appointmentDetails.reduce((accumulator, object) => {
                if (object?.withPrice) {
                  return accumulator + (object?.price || 0);
                } else {
                  return accumulator;
                }
              }, 0)}
            </th>
          </tr>
        </thead>
        {footerToggle && (
          <tbody>
            {appointmentDetails.map((item) => (
              <tr>
                <td style={{ textAlign: "left" }}>{item.title}</td>
                <td style={{ textAlign: "right" }}>${item.price}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {!footerToggle && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              backgroundColor: "#52A1FF",
              borderRadius: "0.25rem",
              width: "2.3rem",
              height: "0.4rem",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export const Form = () => {
  const formBodyButtonsContainer = useRef<HTMLDivElement>(null);
  const [footerToggle, setFooterToggle] = useState(
    window.innerWidth < 1024 ? false : true
  );
  const { service } = useParams();
  const [appointmentDetails, setAppointmentDetails] =
    useState<AppointmentDetails>([]);
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const [width] = useWindowSize();
  useEffect(() => {
    if (window.innerWidth < 1024) {
      if (formBodyButtonsContainer.current && formFooterContainer.current) {
        formBodyButtonsContainer.current.style.marginBottom =
          formBodyButtonsContainer.current.clientHeight +
          formFooterContainer.current.clientHeight +
          "px";
      }
    }
  }, [appointmentDetails, footerToggle, width]);
  const formFooterContainer = useRef<HTMLDivElement>(null);
  const [formData, setFormData] = useState({ Date: [] });
  const flows = {
    TV60OrSmaller: [
      "TVCables",
      "TVMount",
      "WallMaterial",
      "Date",
      "Time",
      "Final",
    ],
    TV65OrLarger: [
      "TVCables",
      "TVMount",
      "WallMaterial",
      "Date",
      "Time",
      "Final",
    ],
    CordAndPower: [
      "CordConcealmentAndPowerRelocationCables",
      "WallMaterial",
      "Date",
      "Time",
      "Final",
    ],
    TVAboveFireplace: [
      "TVCables",
      "TVMount",
      "WallMaterial",
      "Date",
      "Time",
      "Final",
    ],
    Soundbar: [
      "SoundbarCables",
      "SoundbarMount",
      "WallMaterial",
      "Date",
      "Time",
      "Final",
    ],
    HomeAudio: [
      "HomeAudioSpeakers",
      "HomeAudioCables",
      "Date",
      "Time",
      "Final",
    ],
  };

  const screens: Screens = {
    TVCables: {
      title: "Conceal cables/wires?",
      options: [
        {
          title: "External cord concealment",
          price: 40,
          name: "concealCablesWires",
          value: "ExternalCordConcealment",
        },
        {
          title: "In-wall cord concealment",
          price: 90,
          name: "concealCablesWires",
          value: "InWallCordConcealment",
        },
        {
          title: "Install Outlet behind the TV",
          price: 200,
          name: "concealCablesWires",
          value: "InstallOutletBehindTheTv",
        },
      ],
      resetButton: "No Concealment",
      multiple: false,
      addons: [
        {
          title: "Dismount existing TV",
          price: 50,
          name: "DismountExistingTV",
        },
        { title: "Mount a Soundbar", price: 50, name: "MountASoundbar" },
      ],
    },
    TVMount: {
      title: "Do you need a mount?",
      options: [
        { title: "Flat mount", price: 35, name: "mount", value: "FlatMount" },
        {
          title: "Tilted mount",
          price: 45,
          name: "mount",
          value: "TiltedMount",
        },
        {
          title: "Full motion mount",
          price: 85,
          name: "mount",
          value: "FullMotionMount",
        },
      ],
      resetButton: "I will supply my own mount",
      multiple: false,
    },
    WallMaterial: {
      title: "Wall material:",
      options: [
        { title: "Drywall", name: "wallMaterial", value: "Drywall" },
        {
          title: "Plaster, brick, concrete or stone",
          price: 15,
          name: "wallMaterial",
          value: "PlasterBrickConcreteOrStone",
        },
        { title: "Wood", price: 30, name: "wallMaterial", value: "Wood" },
      ],
      resetButton: "Not Sure...",
      multiple: false,
    },
    CordConcealmentAndPowerRelocationCables: {
      title: "Select Preferred Cable Concealment:",
      options: [
        {
          title: "External cord concealment (cord cover)",
          price: 80,
          name: "preferredCableConcealment",
          value: "ExternalCordConcealmentCordCover",
        },
        {
          title: "In-wall cord concealment",
          price: 120,
          name: "preferredCableConcealment",
          value: "InWallCordConcealment",
        },
        {
          title: "Install Outlet behind the TV",
          price: 250,
          name: "preferredCableConcealment",
          value: "InstallOutletBehindTheTV",
        },
      ],
      resetButton: "No Concealment",
      multiple: false,
    },
    SoundbarCables: {
      title: "Conceal cables/wires?",
      options: [
        {
          title: "External cord concealment",
          price: 25,
          name: "concealCablesWires",
          value: "ExternalCordConcealment",
        },
        {
          title: "In-wall cord concealment",
          price: 35,
          name: "concealCablesWires",
          value: "InWallCordConcealment",
        },
      ],
      resetButton: "No Concealment",
      multiple: false,
    },
    SoundbarMount: {
      title: "Do you need a mount?",
      options: [
        {
          title: "Flat soundbar mount",
          price: 25,
          name: "soundbarMount",
          value: "FlatSoundbarMount",
        },
        {
          title: "Under TV motion soundbar (for a Full Motion TV)",
          price: 50,
          name: "soundbarMount",
          value: "UnderTVMotionSoundbarForAFullMotionTV",
        },
      ],
      resetButton: "I will supply my own mount",
      multiple: false,
    },
    HomeAudioSpeakers: {
      title: "How many speakers do you have?",
      options: [
        {
          title: "from 1 to 3 speakers",
          price: 110,
          name: "speakers",
          value: "From1To3Speakers",
        },
        {
          title: "from 3 to 5 speakers",
          price: 150,
          name: "speakers",
          value: "From3To5Speakers",
        },
        {
          title: "from 5 to 8 speakers",
          price: 250,
          name: "speakers",
          value: "From5To8Speakers",
        },
      ],
      multiple: false,
    },
    HomeAudioCables: {
      title: "Conceal cables/wires?",
      options: [
        {
          title: "In-wall cord concealment",
          price: 200,
          name: "homeAudioConcealCablesWires",
          value: "InWallCordConcealment",
        },
      ],
      resetButton: "No concealment",
      multiple: false,
    },
    Date: {
      title: "We're ready to get started! What time works for you?",
      fields: [
        {
          placeholder: "Please, choose date and time",
          type: "date",
          name: "Date",
        },
      ],
    },
    Time: {
      title: "We're ready to get started! What time works for you?",
      options: [
        {
          title: "Early Morning between 6am and 9am",
          name: "time1",
          value: "Early Morning between 6am and 9am",
        },
        {
          title: "Morning between 9am and 12pm",
          name: "time2",
          value: "Morning between 9am and 12pm",
        },
        {
          title: "Afternoon between 12pm and 3pm",
          name: "time3",
          value: "Afternoon between 12pm and 3pm",
        },
        {
          title: "Late Afternoon between 3pm and 6pm",
          name: "time4",
          value: "Late Afternoon between 3pm and 6pm",
        },
        {
          title: "Evening between 6pm and 9pm",
          name: "time5",
          value: "Evening between 6pm and 9pm",
        },
      ],
      multiple: true,
    },
    Final: {
      title: "Lastly,",
      fields: [
        { placeholder: "Full name", type: "text", name: "FullName" },
        { placeholder: "Email address", type: "text", name: "Email" },
        { placeholder: "Phone number", type: "text", name: "PhoneNumber" },
        {
          placeholder: "What's your address",
          type: "address",
          name: "Address",
        },
      ],
    },
  };

  const [currentScreen, setCurrentScreen] = useState(
    screens[flows["TV60OrSmaller"][0] as keyof Screens]
  );

  const currentScreenWithStep = useRef<{
    flow: keyof Flows;
    screen: keyof Screens;
    step: number;
  }>({
    flow: "TV60OrSmaller",
    screen: flows["TV60OrSmaller"][0] as keyof Screens,
    step: 0,
  });

  const getCurrentScreenWithStep = () => currentScreenWithStep.current;

  const setCurrentScreenNumberStep = (step: number, flow: keyof Flows) => {
    currentScreenWithStep.current = {
      screen: flows[flow][step] as keyof Screens,
      flow: flow || currentScreenWithStep.current.flow,
      step: step,
    };
  };

  const formHeaderData = useRef<{
    title: string;
    params?: { oldPrice: number; newPrice: number };
    price?: number;
  }>({
    title: "TV Mounting & Installation 60″ or Smaller",
    params: { oldPrice: 100, newPrice: 85 },
  });

  useEffect(() => {
    if (service === "TV Mounting & Installation 60″ or Smaller") {
      showScreenWithStep("TV60OrSmaller", 0);
      setAppointmentDetails([
        {
          title: "TV Mounting & Installation 60″ or Smaller",
          price: 85,
          withPrice: true,
        },
      ]);
      formHeaderData.current = {
        title: "TV Mounting & Installation 60″ or Smaller",
        params: { oldPrice: 100, newPrice: 85 },
      };
    }
    if (service === "TV Mounting & Installation 65″ or Larger") {
      showScreenWithStep("TV65OrLarger", 0);
      setAppointmentDetails([
        {
          title: "TV Mounting & Installation 65″ or Larger",
          price: 120,
          withPrice: true,
        },
      ]);
      formHeaderData.current = {
        title: "TV Mounting & Installation 65″ or Larger",
        params: { oldPrice: 150, newPrice: 120 },
      };
    }
    if (service === "Cord Concealment & Power Relocation Installation") {
      showScreenWithStep("CordAndPower", 0);
      setAppointmentDetails([
        {
          title: "Cord Concealment & Power Relocation Installation",
          price: 0,
          withPrice: true,
        },
      ]);
      formHeaderData.current = {
        title: "Cord Concealment & Power Relocation Installation",
      };
    }
    if (service === "TV Above the Fireplace") {
      showScreenWithStep("TVAboveFireplace", 0);
      setAppointmentDetails([
        {
          title: "TV Above the Fireplace",
          price: 200,
          withPrice: true,
        },
      ]);
      formHeaderData.current = {
        title: "TV Above the Fireplace",
        price: 200,
      };
    }
    if (service === "Soundbar Installation") {
      showScreenWithStep("Soundbar", 0);
      setAppointmentDetails([
        {
          title: "Soundbar Installation",
          price: 85,
          withPrice: true,
        },
      ]);
      formHeaderData.current = {
        title: "Soundbar Installation",
        price: 85,
      };
    }
    if (service === "Home Audio Installation") {
      showScreenWithStep("HomeAudio", 0);
      setAppointmentDetails([
        {
          title: "Home Audio Installation",
          price: 150,
          withPrice: true,
        },
      ]);
      formHeaderData.current = {
        title: "Home Audio Installation",
        price: 150,
      };
    }
  }, []);

  const showScreenWithStep = (flow: keyof Flows, step: number) => {
    setCurrentScreenNumberStep(step, flow);
    setCurrentScreen(
      screens[flows[flow as keyof Flows][step] as keyof Screens]
    );
  };

  const onChangeCalendar = (data: any) => {
    if (data.length > 4) data.shift();
    setFormData({
      ...formData,
      Date: data,
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let extra = {};
    const currentScreen = getCurrentScreenWithStep();
    const screen = screens[
      getCurrentScreenWithStep().screen
    ] as screenWithCheckboxes;
    if (e.target.type === "checkbox" && !e.target.name.includes("reset")) {
      extra = { ["reset" + currentScreen.screen]: false };
      if (e.target.checked) {
        const options = screen.options.filter(
          (option) => option.name === e.target.name
        );

        const addons = screen?.addons?.filter(
          (addon) => addon.name === e.target.name
        );

        let option: { title: string; name: string; price?: number } = {
          title: "",
          name: "",
          price: 0,
        };

        if (options.length > 0) option = options[0];
        if (addons && addons?.length > 0) option = addons[0];

        setAppointmentDetails([
          ...appointmentDetails,
          {
            title: option.title,
            name: option.name,
            price: option?.price || 0,

            withPrice: true,
          },
        ]);
      } else {
        let tmp = [...appointmentDetails];
        const index = tmp.findIndex((item) => item.name === e.target.name);
        if (index > -1) {
          tmp.splice(index, 1);
        }
        setAppointmentDetails(tmp);
      }
    }

    if (e.target.type === "radio") {
      if (e.target.checked) {
        let newValue = [...appointmentDetails];

        const restOptionsValues = screen.options.map(
          (option) => option?.value || ""
        );

        for (const value of restOptionsValues) {
          const index = newValue.findIndex((item) => item.value === value);
          if (index > -1) {
            newValue.splice(index, 1);
          }
        }

        const option = screen.options.filter(
          (option) => option.value === e.target.value
        )[0];

        newValue = [
          ...newValue,
          {
            title: option.title,
            name: option.name,
            price: option?.price || 0,
            value: option?.value,
            withPrice: true,
          },
        ];

        setAppointmentDetails(newValue);
      }
    }

    if (e.target.type === "radio") {
      setFormData({
        ...formData,
        [e.target.name]: { checked: e.target.checked, value: e.target.value },
        ...extra,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
        ...extra,
      });
    }
  };

  return (
    <div className="px-8 max-w-7xl mx-auto">
      <FormHeader {...formHeaderData.current} />
      <div className="lg:grid lg:gap-x-10 lg:grid-cols-2">
        <FormBody
          {...currentScreen}
          formBodyButtonsContainer={formBodyButtonsContainer}
          formData={formData}
          setFormData={setFormData}
          onChange={onChange}
          onChangeCalendar={onChangeCalendar}
          getCurrentScreenWithStep={getCurrentScreenWithStep}
          showScreenWithStep={showScreenWithStep}
          appointmentDetails={appointmentDetails}
          setAppointmentDetails={setAppointmentDetails}
        />
        <FormFooter
          footerToggle={footerToggle}
          setFooterToggle={setFooterToggle}
          appointmentDetails={appointmentDetails}
          formFooterContainer={formFooterContainer}
        />
      </div>
    </div>
  );
};
