import { FeedbackFormProps } from "../../../@types/props/components/organisms/FeedbackForm";
import { Button } from "../../atoms/Button";
import { Field } from "../../atoms/Field";
import { Radio } from "../../atoms/Radio";
import { Text } from "../../atoms/Text";
import { Title } from "../../atoms/Title";
import { Cols2, Container } from "./css";

export const FeedbackForm = ({
  onChange,
  onSubmit,
  option,
  firstName,
  lastName,
  email,
  companyName,
  additionalInfo,
}: FeedbackFormProps) => {
  return (
    <Container>
      <Title type="feedbackForm">What option you're interested in?</Title>
      <Radio
        checked={option}
        onChange={onChange}
        title="Services"
        name="option"
        value="Services"
      />
      <Radio
        checked={option}
        onChange={onChange}
        title="Membership"
        name="option"
        value="Membership"
      />
      <Radio
        checked={option}
        onChange={onChange}
        title="Dedicated Desk"
        name="option"
        value="Dedicated Desk"
      />
      <Radio
        checked={option}
        onChange={onChange}
        title="Just a tour"
        name="option"
        value="Just a tour"
      />
      <Title type="feedbackForm">Your details</Title>
      <Cols2>
        <Field
          type="text"
          placeholder="First Name*"
          name="firstName"
          value={firstName}
          onChange={onChange}
        />
        <Field
          type="text"
          placeholder="Last Name*"
          name="lastName"
          value={lastName}
          onChange={onChange}
        />
      </Cols2>
      <Cols2 extendCSS={{ marginTop: "0.75rem" }}>
        <Field
          type="text"
          placeholder="Email*"
          name="email"
          value={email}
          onChange={onChange}
        />
        <Field
          type="text"
          placeholder="Company name"
          name="companyName"
          value={companyName}
          onChange={onChange}
        />
      </Cols2>
      <Field
        extendCSS={{ marginTop: "0.75rem", width: "100%" }}
        type="textArea"
        placeholder="Additional info"
        name="additionalInfo"
        value={additionalInfo}
        onChange={onChange}
      />
      <Text variant="feedbackForm">
        By clicking the button below you agree to our Terms of Service and have
        read and understood our Privacy Policy
      </Text>
      <Button
        extendCSS={{ marginTop: "0.75rem", width: "9rem" }}
        onClick={onSubmit}
      >
        Submit
      </Button>
    </Container>
  );
};
