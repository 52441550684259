import { useDispatch, useSelector } from "react-redux";
// import {
//   selectGlobalRoot,
//   actions as globalActions,
// } from "../../../store/global";
import React, { useEffect, useState } from "react";
import { useQueryGlobal } from "../../../queries/global";
import { Services } from "../../organisms/Services";
import tv60inch from "../../../assets/images/tv60inch.jpg";
import tv65inchlarger from "../../../assets/images/tv65inchlarger.jpg";
import tvHideWires from "../../../assets/images/tv-hide-wires.jpg";
import tvAboveFireplace from "../../../assets/images/tv-above-fireplace.jpg";
import soundbartv from "../../../assets/images/soundbartv.jpg";
import homeAudio from "../../../assets/images/home-audio.jpg";
import { Hero } from "../../molecules/Hero";
import { Testimonials } from "../../organisms/Testimonials";
import { Title } from "../../atoms/Title";
import { Text } from "../../atoms/Text";
import { FAQGrid } from "../../organisms/FAQGrid";
import { FeedbackForm } from "../../organisms/FeedbackForm";
import { useMutation, useQueryClient } from "react-query";
import { postForm } from "../../../queries/feedbackForm";

export const Home = () => {
  // const dispatch = useDispatch();
  // const state = useSelector(selectGlobalRoot);
  // console.log(state);
  // useEffect(() => {
  //   dispatch(globalActions.GET_GLOBAL());
  // }, [dispatch]);
  // console.log("rerender");
  // const { data, isLoading, isError } = useQueryGlobal();
  // console.log(data);

  const [feedbackFormData, setFeedbackFormData] = useState({
    option: "",
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    additionalInfo: "",
  });
  const feedbackFormOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeedbackFormData({
      ...feedbackFormData,
      [e.target.name]: e.target.value,
    });
  };
  const { mutate: mutateForm } = useMutation(postForm, {
    onSuccess: (data) => {
      const message = "success";
      alert(message);
    },
    onError: () => {
      alert("there was an error");
    },
    onSettled: () => {},
  });
  const feedbackFormOnSubmit = () => {
    const postData = {
      auth: "notBot",
      ...feedbackFormData,
    };

    mutateForm(postData);
  };
  return (
    <React.Fragment>
      {
        //!isLoading && !isError &&
        <React.Fragment>
          <Hero />
          <Services
            services={[
              {
                imageSrc: tv60inch,
                imageAlt: "TV Mounting & Installation 60″ or Smaller",
                href: "#",
                title: "TV Mounting & Installation 60″ or Smaller",
                price: 85,
                oldPrice: 100,
              },
              {
                imageSrc: tv65inchlarger,
                imageAlt: "TV Mounting & Installation 65″ or Larger",
                href: "#",
                title: "TV Mounting & Installation 65″ or Larger",
                price: 120,
                oldPrice: 150,
              },
              {
                imageSrc: tvHideWires,
                imageAlt: "Cord Concealment & Power Relocation Installation",
                href: "#",
                title: "Cord Concealment & Power Relocation Installation",
                price: 80,
              },
              {
                imageSrc: tvAboveFireplace,
                imageAlt: "TV Above the Fireplace",
                href: "#",
                title: "TV Above the Fireplace",
                price: 200,
              },
              {
                imageSrc: soundbartv,
                imageAlt: "Soundbar Installation",
                href: "#",
                title: "Soundbar Installation",
                price: 85,
              },
              {
                imageSrc: homeAudio,
                imageAlt: "Home Audio Installation",
                href: "#",
                title: "Home Audio Installation",
                price: 150,
              },
            ]}
          />
          <div id="testimonials">
            <div style={{ textAlign: "center" }}>
              <Title type="homeH2" extendCSS={{ marginBottom: "0.438rem" }}>
                What homeowners are saying
              </Title>
              <Text variant="homeP" extendCSS={{ marginBottom: "4.5rem" }}>
                <span>
                  Problems trying to resolve the conflict between <br /> the two
                  major realms of Classical physics: Newtonian mechanics
                </span>
              </Text>
            </div>
            <Testimonials
              testimonials={[
                {
                  name: "David. P.",
                  content:
                    "1Was responded to same day when looking for assistance and was able to get a next day appointment. The tech was professional, timely and the work was exactly what I wanted.",
                  stars: 1,
                },
                {
                  name: "David. P.",
                  content:
                    "2Was responded to same day when looking for assistance and was able to get a next day appointment. The tech was professional, timely and the work was exactly what I wanted.",
                  stars: 1,
                },
                {
                  name: "David. P.",
                  content:
                    "3Was responded to same day when looking for assistance and was able to get a next day appointment. The tech was professional, timely and the work was exactly what I wanted.",
                  stars: 1,
                },
                {
                  name: "David. P.",
                  content:
                    "4Was responded to same day when looking for assistance and was able to get a next day appointment. The tech was professional, timely and the work was exactly what I wanted.",
                  stars: 1,
                },
                {
                  name: "David. P.",
                  content:
                    "5Was responded to same day when looking for assistance and was able to get a next day appointment. The tech was professional, timely and the work was exactly what I wanted.",
                  stars: 1,
                },
                {
                  name: "David. P.",
                  content:
                    "6Was responded to same day when looking for assistance and was able to get a next day appointment. The tech was professional, timely and the work was exactly what I wanted.",
                  stars: 1,
                },
                {
                  name: "David. P.",
                  content:
                    "7Was responded to same day when looking for assistance and was able to get a next day appointment. The tech was professional, timely and the work was exactly what I wanted.",
                  stars: 1,
                },
                {
                  name: "David. P.",
                  content:
                    "8Was responded to same day when looking for assistance and was able to get a next day appointment. The tech was professional, timely and the work was exactly what I wanted.",
                  stars: 1,
                },
              ]}
            />
          </div>
          <div id="faq" style={{ marginTop: "5.4rem" }}>
            <div style={{ textAlign: "center" }}>
              <Title type="homeH2" extendCSS={{ marginBottom: "0.438rem" }}>
                FAQ
              </Title>
              <Text variant="homeP" extendCSS={{ marginBottom: "4.5rem" }}>
                <span>
                  Problems trying to resolve the conflict between
                  <br />
                  the two major realms of Classical physics: Newtonian mechanics
                </span>
              </Text>
            </div>
            <FAQGrid
              FAQ={[
                {
                  title: "What form of payment do you accept?",
                  body: "We accept Venmo, Zelle or Cash.",
                },
                {
                  title: "How long will installation take?",
                  body: "In most cases less than an hour.",
                },
                {
                  title: "Where is the best place to mount my tv?",
                  body: "We evaluate possible TV mounting locations to help give you an idea where to place your TV.",
                },
                {
                  title: "Are cables for installation included in the price?",
                  body: "Cables are not included in the price. We can supply any type of cables for a fee.",
                },
                {
                  title: "How high should i mount my TV?",
                  body: "Eye level of the average adult in a seated pose. Might vary according to the room type.",
                },
                {
                  title: "the quick fox jumps over the lazy dog",
                  body: "Things on a very small scale behave like",
                },
                {
                  title: "the quick fox jumps over the lazy dog",
                  body: "Things on a very small scale behave like",
                },
                {
                  title: "the quick fox jumps over the lazy dog",
                  body: "Things on a very small scale behave like",
                },
                {
                  title: "the quick fox jumps over the lazy dog",
                  body: "Things on a very small scale behave like",
                },
              ]}
            />
          </div>
          <div id="feedbackForm" style={{ marginTop: "5.4rem" }}>
            <div style={{ textAlign: "center" }}>
              <Title type="homeH2" extendCSS={{ marginBottom: "0.438rem" }}>
                Let's get in touch
              </Title>
              <Text variant="homeP" extendCSS={{ marginBottom: "3.5rem" }}>
                Leave your details below and get a free trial or a tour.
              </Text>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FeedbackForm
                {...feedbackFormData}
                onChange={feedbackFormOnChange}
                onSubmit={feedbackFormOnSubmit}
              />
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};
